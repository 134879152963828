var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"env-pm-view"}},[_c('div',{staticClass:"spot-key"},[_c('span',[_vm._v(_vm._s(_vm.$route.params.key))])]),_c('div',{staticClass:"content-box"},[_c('div',{staticClass:"map-list"},[_c('button',{staticClass:"btn-cctv",on:{"click":_vm.goCctvList}},[_c('img',{attrs:{"src":require("../assets/camera.png")}}),_c('span',[_vm._v("CCTV 整合")])]),_vm._l((_vm.stationInfoList),function(stationInfo){return _c('img',{key:stationInfo.key,staticClass:"preview-dot",class:{ active: stationInfo.key === _vm.$route.params.key },attrs:{"src":require('../assets/' + stationInfo.key + '-dot.png')},on:{"click":function($event){return _vm.goStation(stationInfo.key)}}})})],2),_c('div',{staticClass:"title"},[_vm._v("作業環境監測")]),_c('table',{staticClass:"table"},[_c('thead',[_c('th',[_vm._v(" "+_vm._s(_vm.airPM.machine_name.substring(_vm.airPM.machine_name.indexOf('_') + 1))+" 點 ")]),_c('th',[_vm._v("數值")]),_c('th',[_vm._v("告知事項")])]),_c('tbody',[_c('tr',[_vm._m(0),_c('td',{class:_vm.setClass(
                _vm.spotInfo.attributes.find((e) => e.key === 'PM2.5').value
              )},[_c('span',[_vm._v(" "+_vm._s(_vm.spotInfo.attributes.find((e) => e.key === 'PM2.5').value)+" ")])]),_c('td',[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require('../assets/' +
                    _vm.setUrl(
                      _vm.spotInfo.attributes.find((e) => e.key === 'PM2.5').value
                    ) +
                    '-face.png')}}),_c('span',[_vm._v(" "+_vm._s(_vm.setMemo( _vm.spotInfo.attributes.find((e) => e.key === 'PM2.5').value ))+" ")])])])])])]),_vm._m(1)]),_vm._m(2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticClass:"pm"},[_vm._v("PM"),_c('span',{staticClass:"small"},[_vm._v("2.5")]),_vm._v("(μg/m"),_c('span',{staticClass:"small top"},[_vm._v("3")]),_c('span',{staticClass:"right-brackets"},[_vm._v(")")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"level"},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/happy-face.png")}}),_c('span',{staticClass:"positive level-name"},[_vm._v("低級：")]),_c('span',[_vm._v("建議可正常活動")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/soso-face.png")}}),_c('span',{staticClass:"medium level-name"},[_vm._v("中級：")]),_c('span',[_vm._v("建議敏感性族群減少戶外活動")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/bad-face.png")}}),_c('span',{staticClass:"negative level-name"},[_vm._v("高級：")]),_c('span',[_vm._v("建議一般民眾應考慮減少戶外活動")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo-group"},[_c('img',{attrs:{"src":require("@/assets/futsu-logo.png"),"id":"futsu-logo"}}),_c('img',{attrs:{"src":require("@/assets/lin-logo.png"),"id":"lin-logo"}}),_c('img',{attrs:{"src":require("@/assets/cec-logo.png"),"id":"cec-logo"}})])
}]

export { render, staticRenderFns }